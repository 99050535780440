import {defineStore} from 'pinia';

export const useCookieStore = defineStore({
  id: 'cookie-store',
  state: () => {
    return {
      token: false,
      user: false,
      state: '',
    }
  },
  getters: {
    appsumoCodes: (state) => {
      if (state.user && state.user.coupon_codes.length > 0) {
        return state.user.coupon_codes.filter((code) => code.type === 'appsumo' && code.is_active);
      }
      return [];
    }
  }

});