export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"facebook-domain-verification","content":"k16c4vrki5rdi8x9is5t9o8pituty8"}],"link":[{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":true},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"icon","type":"image/png","href":"/favicon-16x16.png","sizes":"16x16"},{"rel":"icon","href":"/favicon-32x32.png","sizes":"32x32"},{"rel":"icon","href":"/android-chrome-192x192.png","sizes":"192x192"},{"rel":"icon","href":"/android-chrome-512x512.png","sizes":"512x512"}],"style":[],"script":[{"src":"https://cdn.tolt.io/tolt.js","async":true,"data-tolt":"046f2c43-574a-476f-91de-d9df520974d3"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false