<template>
  <NuxtLayout>

  <div class="max-w-xl mx-auto px-4 md:px-6 mt-20 pt-8 lg:pt-16 text-center">
      <div v-if="refreshRequired">
        <h1 class="text-4xl font-bold mb-4 font-poppins ">{{ $t('code.updated.heading') }}</h1>
         <p class="text-p2s md:text-p2m lg:text-p2l mb-8">{{ $t('code.updated.subheading') }}</p>
        <button @click="refreshPage"
                class="font-bold text-white text-center bg-brand-500 justify-center w-52 text-sm rounded-m py-3 px-6 mb-4 md:mb-6 flex items-center cursor-pointer mx-auto" >
          <span>{{ $t('refresh.page') }}</span>
        </button>
      </div>
      <div v-else>
        <h1 class="text-4xl font-bold mb-4 font-poppins ">{{ $t('error.page.title') }}</h1>
        <p class="text-red-error text-p1s md:text-p1m lg:text-p1l mb-6 flex justify-center items-center gap-1 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
            <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
          </svg>
           <strong v-if="error">{{ error.message }}</strong>
        </p>
        <p v-if="error && error.statusCode !== 404" class="text-p2s md:text-p2m lg:text-p2l mb-6 sm:px-8">{{ $t('error.page.description') }}</p>
        <p v-else class="text-p2s md:text-p2m lg:text-p2l mb-6 sm:px-8">{{ $t('404.page.description') }}</p>
        <a :href="localePath('/')" class="font-bold text-white text-center bg-brand-500 justify-center w-52 text-sm rounded-m py-3 px-6 mb-4 md:mb-6 flex items-center cursor-pointer mx-auto">{{ $t('error.return.home') }}</a>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup>
  const error = useError();

  definePageMeta({
    layout: "default",
  });

  const { $sentryCaptureException } = useNuxtApp();
  const refreshRequired = ref(false);

  if (error.value && error.value.message
      && error.value.message.indexOf('Failed to fetch dynamically imported module') > -1) {
    refreshRequired.value = true;
  }

  if ($sentryCaptureException && error.value && error.value.statusCode !== 404) {
    let errorMessage = error.value.message;
    if (error.value.statusCode) {
      errorMessage = `${error.value.statusCode} - ${error.value.message}`;
    }
    if (!errorMessage) {
      errorMessage = 'Unknown Error';
    }
    $sentryCaptureException(new Error(errorMessage));
  }

  const refreshPage = () => {
    window.location.reload();
  }

</script>

<style scoped>

</style>