export default {
  default: () => import("/vercel/path0/layouts/default.vue").then(m => m.default || m),
  defaultgrey: () => import("/vercel/path0/layouts/defaultgrey.vue").then(m => m.default || m),
  editor: () => import("/vercel/path0/layouts/editor.vue").then(m => m.default || m),
  fullheight: () => import("/vercel/path0/layouts/fullheight.vue").then(m => m.default || m),
  iframe: () => import("/vercel/path0/layouts/iframe.vue").then(m => m.default || m),
  justgrey: () => import("/vercel/path0/layouts/justgrey.vue").then(m => m.default || m),
  nofooter: () => import("/vercel/path0/layouts/nofooter.vue").then(m => m.default || m),
  nosidebar: () => import("/vercel/path0/layouts/nosidebar.vue").then(m => m.default || m),
  nosidebargrey: () => import("/vercel/path0/layouts/nosidebargrey.vue").then(m => m.default || m),
  sidebar: () => import("/vercel/path0/layouts/sidebar.vue").then(m => m.default || m),
  sidebargrey: () => import("/vercel/path0/layouts/sidebargrey.vue").then(m => m.default || m),
  sidebarnopadding: () => import("/vercel/path0/layouts/sidebarnopadding.vue").then(m => m.default || m),
  simple: () => import("/vercel/path0/layouts/simple.vue").then(m => m.default || m),
  video: () => import("/vercel/path0/layouts/video.vue").then(m => m.default || m)
}