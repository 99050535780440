import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/vercel/path0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_HQNyLQlVwt from "/vercel/path0/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import i18n_Fspu1Dj02B from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import composition_sLxaNGmlSL from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_client_mZYnNRQBH8 from "/vercel/path0/plugins/analytics.client.js";
import auth_token_tRNV5t7i12 from "/vercel/path0/plugins/auth-token.js";
import clarity_client_hudlDOj6WH from "/vercel/path0/plugins/clarity.client.js";
import clickoutside_Uo9RXyvY62 from "/vercel/path0/plugins/clickoutside.js";
import maska_r8BHBbSmHt from "/vercel/path0/plugins/maska.js";
import mitt_ZNXaeqsgM5 from "/vercel/path0/plugins/mitt.js";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import tabs_gN7oWC2h24 from "/vercel/path0/plugins/tabs.js";
import toastify_ScRgZgP9op from "/vercel/path0/plugins/toastify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  plugin_HQNyLQlVwt,
  i18n_Fspu1Dj02B,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  analytics_client_mZYnNRQBH8,
  auth_token_tRNV5t7i12,
  clarity_client_hudlDOj6WH,
  clickoutside_Uo9RXyvY62,
  maska_r8BHBbSmHt,
  mitt_ZNXaeqsgM5,
  sentry_client_shVUlIjFLk,
  tabs_gN7oWC2h24,
  toastify_ScRgZgP9op
]