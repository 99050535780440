import VueGtag, { useGtag } from 'vue-gtag-next'

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()
    if(config.public.productionMode) {
        nuxtApp.vueApp.use(VueGtag, {
            property: {
                id: config.public.googleAnalyticsId
            }
        })

        const { event } = useGtag();

        return {
            provide : {
                gtEvent: (name, params) => {
                    event(name, params)
                }
            }
        }
    }
})