import {useCookieStore} from "~/store/cookie";

const AUTH_TOKEN_NAME = "sms-auth-token";

const COOKIE_OPTIONS = {
    default: () => {
        return { token: null }
    },
    sameSite: 'lax',
    maxAge: 60 * 60 * 24 * 30,
    secure: true
}

export const useAuthToken = () => {
    const cookieStore = useCookieStore();
    try {
        cookieStore.$patch(useCookie(AUTH_TOKEN_NAME, COOKIE_OPTIONS).value);
    }
    catch (e) {
        console.log(e);
    }

    cookieStore.$subscribe((mutation, state) => {
        try
        {
           if (mutation.type === 'direct') {
                useCookie(AUTH_TOKEN_NAME, COOKIE_OPTIONS).value = state;
           }
        }
        catch (e) {
            //console.log(e);
        }

    });
}